.icon-bianji[data-v-4cdb48a8], .icon-shanchu[data-v-4cdb48a8], .icon-guanlishezhi[data-v-4cdb48a8], .icon-pingzheng[data-v-4cdb48a8] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 13px;
  line-height: 24px;
}
.pagination[data-v-4cdb48a8] {
  margin-top: 15px;
}
.content[data-v-4cdb48a8] {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
}
.content tr[data-v-4cdb48a8] {
  padding: 0;
  margin: 0;
}
.content td[data-v-4cdb48a8] {
  border-bottom: 1px solid #c2c2c2;
  line-height: 38px;
  padding: 0 6px;
  color: #333;
  font-size: 13px;
}
.center[data-v-4cdb48a8] {
  text-align: center;
}
.top_select[data-v-4cdb48a8] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}