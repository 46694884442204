.iconStyle[data-v-676b1a0c] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
}
.el-table--mini .el-table__cell[data-v-676b1a0c] {
  padding: 0;
}
.pagination[data-v-676b1a0c] {
  margin-top: 15px;
}
.top_select[data-v-676b1a0c] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 5px;
}
.steps[data-v-676b1a0c] {
  width: 1080px;
  margin: 0 auto;
  padding: 6px 0;
}
.top[data-v-676b1a0c] {
  position: relative;
  background-image: linear-gradient(180deg, var(--themeColorStepBack, rgba(23, 162, 184, 0.2)) 0%, rgba(25, 182, 199, 0) 63%);
  box-shadow: 0 2px 10px #ddd;
}
[data-v-676b1a0c] .el-step__line {
  background-color: var(--themeColor, #17a2b8);
}
[data-v-676b1a0c] .el-step.is-horizontal .el-step__line {
  height: 1px;
}
[data-v-676b1a0c] .el-step__icon-inner {
  font-weight: 500;
}
.switch_icon[data-v-676b1a0c] {
  position: absolute;
  right: 2px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  z-index: 1;
  display: flex;
  align-items: center;
}
.switch_icon span[data-v-676b1a0c] {
  font-size: 12px;
  margin-right: 2px;
}
[data-v-676b1a0c] .el-step__title {
  font-size: 13px;
}
[data-v-676b1a0c] .el-step__icon {
  font-size: 13px;
}
[data-v-676b1a0c] .el-step__title.is-process {
  font-weight: 500;
  color: #666;
}
[data-v-676b1a0c] .el-step__icon.is-text {
  border: 1px solid;
  background-color: var(--themeColor, #17a2b8);
  color: #fff;
}
[data-v-676b1a0c] .el-dropdown-menu__item {
  display: block;
}
[data-v-676b1a0c] .el-radio {
  height: 50px;
  align-items: flex-start;
  margin-right: 23px;
}
[data-v-676b1a0c] .el-radio__inner {
  margin-top: 3px;
}