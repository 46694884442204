.title[data-v-4c965097] {
  background-color: #fef6ed;
  font-size: 13px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #333;
  border: 1px solid #ffe1b7;
  border-radius: 3px;
}